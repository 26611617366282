import './VisualToolLink.scss';

import React from 'react';
import { Link } from 'gatsby';

import compareDomainsImage from 'assets/images/visualizations/compare-domains.svg';
import compareFrameworksImage from 'assets/images/visualizations/compare-frameworks.svg';
import compareTermsImage from 'assets/images/visualizations/compare-terms.svg';
import standardsAlignmentsImage from 'assets/images/visualizations/standards-alignments.svg';
import developmentalProgressionsImage from 'assets/images/visualizations/developmental-progressions.svg';

type DisplayStyle = 'thumbnail' | 'block' | 'banner' | 'index';

interface Props {
  title: string;
  description: string;
  buttonText: string;
  linkTo: string;
  displayStyle: DisplayStyle;
  image?: string;
  color?: string;
  state?: { frameworkID?: number; stateStandardsID?: number };
}

/**
 * A large link to one of the main visualizations on the site, used in a number
 * of places at different sizes.
 *
 * Rather than using this directly, a configured component for each type of
 * visualization is exported below.
 *
 * Props are still passed through to the `Link` so specific alterations can be
 * made.
 */
export const VisualToolLink: React.FC<Props> = (props) => {
  const {
    title,
    description,
    buttonText,
    linkTo,
    displayStyle,
    image,
    color,
    ...linkProps
  } = props;

  return (
    <Link
      className={`VisualToolLink VisualToolLink-${displayStyle}`}
      to={linkTo}
      style={{ backgroundImage: `url(${image})` }}
      {...linkProps}
    >
      <div>
        <h2 className="VisualToolLink-content-heading">
          {displayStyle === 'thumbnail' ? buttonText : title}
        </h2>
        {displayStyle !== 'thumbnail' && <p>{description}</p>}
      </div>
      {displayStyle !== 'thumbnail' && (
        <div
          className="VisualToolLink-button no-print"
          style={color ? { backgroundColor: color } : {}}
        >
          {buttonText}
        </div>
      )}
    </Link>
  );
};

interface ConcreteLinkProps {
  displayStyle: DisplayStyle;
  description?: string;
  state?: { frameworkID?: number; stateStandardsID?: number };
}

export const CompareDomainsLink = (props: ConcreteLinkProps) => (
  <VisualToolLink
    title="Explore Domain Focus"
    description="See how much each framework focuses on six common areas of SEL."
    buttonText="Compare Domains"
    linkTo="/compare-domains/"
    image={compareDomainsImage}
    {...props}
  />
);

export const CompareFrameworksLink = (props: ConcreteLinkProps) => (
  <VisualToolLink
    title="Discover Framework Connections"
    description="See where skills in one framework are related to skills in another."
    buttonText="Compare Frameworks"
    linkTo="/compare-frameworks/"
    image={compareFrameworksImage}
    {...props}
  />
);

export const CompareTermsLink = (props: ConcreteLinkProps) => (
  <VisualToolLink
    title="Identify Related Skills"
    description="See where similar SEL skills appear across frameworks."
    buttonText="Compare Terms"
    linkTo="/compare-terms/"
    image={compareTermsImage}
    {...props}
  />
);

export const AlignmentLink = (props: ConcreteLinkProps) => (
  <VisualToolLink
    title="Compare State Standards Alignment"
    description="See how state standards compare to frameworks"
    buttonText="Compare State Standards Alignment"
    linkTo="/state-standards/alignment/"
    image={standardsAlignmentsImage}
    {...props}
  />
);

export const DevelopmentalProgressionsLink = (props: ConcreteLinkProps) => (
  <VisualToolLink
    title="See Developmental Progression"
    description="Analyze how standards cover developmental progressions"
    buttonText="Developmental Progression"
    linkTo="/state-standards/developmental-progression/"
    image={developmentalProgressionsImage}
    {...props}
  />
);
